import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {
  Alert,
  AlertColor,
  Button,
  Chip,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Snackbar,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import { camelCase, debounce, get, mapKeys, pickBy, snakeCase } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import axios from '../../../axios';
import { useAuth } from '../../../context/AuthContext';
import { Customer } from '../../../models/customer.model';
import { Order } from '../../../models/order.model';
import { Product } from '../../../models/product.model';
import doesRoleExist from '../../../utils/doesRoleExist';
import ExportDataDialog from '../ExportDialog';
import AddOrderDialog, { AddOrderInput } from './AddOrderDialog';
import OrdersTable, { Data } from './OrdersTable';

export default function OrdersPage() {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [filter, setFilter] = useState('');
  const { organization, user, subscription } = useAuth();
  const history = useHistory();
  const [rows, setRows] = React.useState<Data[]>([]);
  const [displayRows, setDisplayRows] = React.useState<Data[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [products, setProducts] = useState<Product[]>([]);

  const handleClose = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const debouncedFilter = useMemo(
    () =>
      debounce((value: string) => {
        if (value === '') {
          setDisplayRows(rows);
        } else {
          setDisplayRows(
            rows.filter((o) =>
              Object.keys(o).some((k) =>
                get(o, k)
                  ?.toString()
                  .toLowerCase()
                  .includes(value.toLowerCase())
              )
            )
          );
        }
      }, 1000),
    [rows]
  );

  const fetchOrders = useCallback(async () => {
    setIsLoading(true);
    const ordersResp = await axios.get(
      `api/organizations/${organization?.id}/orders`
    );
    const ordersData = ordersResp.data.data.items.map((item: any) => {
      item.customer = mapKeys(item.customer, (v, k) => camelCase(k));
      return mapKeys(item, (v, k) => camelCase(k));
    });
    const data = ordersData.map((order: Order) => ({
      id: order.id.toString(),
      code: order.code,
      orderDate: order.orderDate,
      totalAmount: order.totalAmount,
      totalDiscount: order.totalDiscount,
      customerName: order.customer.shortName,
      taxAmount: order.taxAmount,
      note: order.note,
      status: order.status,
    }));
    setRows(data);
    setDisplayRows(data);
    setIsLoading(false);
  }, [organization?.id]);

  const [alertProps, setAlertProps] = useState<{
    severity: AlertColor;
    isOpen: boolean;
    text: string;
  }>({
    severity: 'success',
    isOpen: false,
    text: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      const customersResp = await axios.get(
        `api/organizations/${organization?.id}/customers`,
        {
          params: {
            filters: {
              type__$ne: 'passive',
            },
            pagination: {
              sort: 'short_name:asc',
            },
          },
        }
      );
      setCustomers(
        customersResp.data.data.items.map((item: any) => {
          const customerObj = mapKeys(item, (v, k) => camelCase(k));
          const customer = new Customer();
          return customer.deserialize(customerObj);
        })
      );

      const productsResp = await axios.get(
        `api/organizations/${organization?.id}/products`,
        {
          params: {
            filters: {
              is_active: true,
            },

            pagination: {
              sort: 'name:asc',
            },
          },
        }
      );

      setProducts(
        productsResp.data.data.items.map((item: any) => {
          const productObj = mapKeys(item, (v, k) => camelCase(k));
          const product = new Product();
          return product.deserialize(productObj);
        })
      );
    };
    fetchData();
  }, [organization?.id]);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  const addNewOrder = useCallback(
    async (data: AddOrderInput) => {
      handleClose();
      const payload: any = mapKeys(pickBy(data), (v, k) => snakeCase(k));

      if (Array.isArray(payload.items) && payload.items.length) {
        payload.items = payload.items.map((i: any) => {
          return {
            product_id: i.productId,
            quantity: i.quantity,
            price: i.price,
            tax_amount: i.taxAmount,
            tax_rate: i.taxRate,
            total_amount: i.totalAmount,
          };
        });
      }

      await axios.post(`api/organizations/${organization?.id}/orders`, payload);

      fetchOrders();
    },
    [fetchOrders, organization?.id, handleClose]
  );

  const [isExportDataDialogOpen, setExportDataDialogOpen] =
    useState<boolean>(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}
      data-testid="ordersPage"
    >
      <Box
        sx={{
          flexGrow: 1,
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          paddingRight: '0px',
          paddingLeft: '0px',
          flexDirection: isMobile ? 'column' : 'row',
          gap: '12px',
        }}
      >
        <TextField
          placeholder="Search..."
          id="search-task"
          value={filter}
          sx={{ width: '25ch' }}
          inputProps={{ 'data-testid': 'searchInput' }}
          onChange={(event) => {
            setFilter(event.target.value);
            debouncedFilter(event.target.value);
          }}
          InputProps={{
            sx: { height: '45px' },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  data-testid="clearFilter"
                  aria-label="clear"
                  onClick={() => {
                    setFilter('');
                    debouncedFilter('');
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            onClick={() => {
              handleMenuClose();
              setIsDialogOpen(true);
            }}
          >
            {t('ORDERS.CREATE')}
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleMenuClose();
              setExportDataDialogOpen(true);
            }}
            disabled={!['ecosh-professional'].includes(subscription?.product)}
          >
            {t('EXPORTS.ORDERS')}
            &nbsp;
            <Chip size="small" label="Professional" />
          </MenuItem>
        </Menu>

        {doesRoleExist(user, organization, [
          'ADMIN',
          'TEAM_MANAGER',
          'TEAM_MEMBER',
        ]) && (
          <Button
            variant="outlined"
            sx={{
              maxWidth: 250,
            }}
            onClick={handleMenuOpen}
            data-testid="editOrdersButton"
          >
            {t('EDIT_ORDERS')}
          </Button>
        )}
      </Box>
      <Box>
        <OrdersTable
          rows={displayRows}
          setSelectedOrder={(orderId: string) => {
            history.push(`./order/${orderId}`);
          }}
          isLoading={isLoading}
        />
      </Box>
      <AddOrderDialog
        isOpen={isDialogOpen}
        handleClose={handleClose}
        onSubmit={addNewOrder}
        customers={customers}
        products={products}
      />
      <ExportDataDialog
        open={isExportDataDialogOpen}
        type="orders"
        onClose={(status: boolean) => {
          setExportDataDialogOpen(false);
          if (status) {
            setAlertProps({
              severity: 'success',
              text: 'EXPORTS.COMPLETED',
              isOpen: true,
            });
          }
        }}
      />

      <Snackbar
        open={alertProps.isOpen}
        autoHideDuration={6000}
        onClose={() => {
          setAlertProps((prev) => ({
            ...prev,
            isOpen: false,
          }));
        }}
      >
        <Alert
          onClose={() => {
            setAlertProps((prev) => ({
              ...prev,
              isOpen: false,
            }));
          }}
          severity={alertProps.severity}
          sx={{ width: '100%' }}
          data-testid="alertId"
        >
          {t(alertProps.text)}
        </Alert>
      </Snackbar>
    </Box>
  );
}
